.nav-bar {
  background-color: #e4002b;
  height: 70px;
  position: sticky;
  top: 0px;
  z-index: 10;
  @include grid(1, 3, 0);
  padding: 0 30px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);
  transition: background-color 0.2s ease;

  img {
    width: 60px;
    margin: 0;
    padding: 5px;
    border-radius: 5px;
    margin-right: 10px;
  }

  .btn-group1 {
    justify-self: flex-start;
    align-self: center;
    flex-wrap: nowrap;

    .nav-btn {
      text-decoration: none;
      margin-right: 50px;
      transition: color 0.2s ease, border 0.2s ease, background-color 0.2s ease,
        opacity 0.2s ease;
    }
  }

  .btn-group2 {
    flex-wrap: nowrap;
    justify-self: center;
    align-self: center;
    @include flex(row, center, center);

    .nav-btn {
      text-decoration: none;
      transition: color 0.2s ease, border 0.2s ease, background-color 0.2s ease,
        opacity 0.2s ease;
      height: 50px;
    }
  }

  .btn-group3 {
    @include flex(row, center, center);
    justify-self: flex-end;
    align-self: center;

    #menu-label {
      color: white;
      margin: 0px 10px 0 0;
      font-weight: bold;
      font-size: 1.5rem;
      transition: color 0.3s ease;
    }

    .nav-btn {
      text-decoration: none;
      margin-right: 50px;
      transition: color 0.2s ease, border 0.2s ease, background-color 0.2s ease,
        opacity 0.2s ease;
    }
  }

  &.menu-open {
    background-color: white;

    #menu-label {
      color: $secondary;
    }
  }
}

.menu-btn {
  cursor: pointer;
  transition: all 0.2s ease-out;
  @include flex(column, center, center);
  margin-top: 5px;

  .menu-btn__line {
    width: 36px;
    height: 5px;
    margin-bottom: 5px;
    background-color: white;
    transition: all 0.2s ease-out;
  }
}

.menu-btn.close {
  transform: rotate(360deg);

  .menu-btn__line:nth-child(1) {
    transform: rotate(45deg) translate(5px, 9.5px);
    background-color: $secondary;
  }

  .menu-btn__line:nth-child(2) {
    opacity: 0;
  }

  .menu-btn__line:nth-child(3) {
    transform: rotate(-45deg) translate(5px, -9.5px);
    background-color: $secondary;
  }
}

.nav-bar__menu {
  overflow: hidden;
  position: fixed;
  z-index: 10;
  width: 100vw;
  height: calc(100vh - 70px);
  background-color: white;
  transform: translateX(4000px);
  transition: all 0.5s ease;

  .mobile-nav-btns {
    display: none;
    margin-top: 40px;
  }

  nav {
    @include flex(column, center, flex-start);
    padding: 20px;
    p,
    .link {
      margin: 10px 20px 5px;
      padding: 0;
      text-align: left;
      font-size: 36px;
      text-decoration: none;
      text-transform: uppercase;
      line-height: 1.15;
      letter-spacing: 2px;
      color: $primary;
      transition: color 0.2s ease;
      cursor: pointer;

      &:hover {
        color: $secondary;
      }

      &.active {
        color: $secondary;
      }
    }

    .spacer-for-line {
      margin-bottom: 15px;
    }

    .link-underline {
      color: transparent;
      width: 0px;
      margin-bottom: 10px;
      margin-left: 20px;
      height: 3px;
      background-color: $primary;
      transition: width 0.2s ease-in, color 0.2s ease;
    }

    .link-underline.active {
      background-color: $secondary;
      //width done in js
    }
  }
}

.spotlight {
  @include flex(row, center, center);
  text-decoration: none;
  margin-top: 20px;
  width: 85%;
  height: 150px;
  background: white;
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.74);
  border-radius: 20px;
  padding: 15px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;

  &:hover {
    transform: scale(1.02);
    box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.5);
  }

  &:nth-of-type(2) {
    img {
      order: 2;
    }
  }

  img {
    width: 175px;
    border-radius: 10px;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.575);
  }

  .text-container {
    @include flex(column, center, center);
    width: 60%;
    margin-left: 25px;

    h3 {
      text-align: center;
      text-decoration: none;
      margin-bottom: 0px;
      color: $primary;
      font-size: 26px;
    }

    p {
      text-align: center;
      margin-top: 10px;
    }
  }
}

.nav-bar__menu.open {
  transform: translateX(0px);
}

.nav-header {
  font-size: 44px;
    text-align: center;
    font-weight: 200;
    color: white;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin: 40px 0;
    border-radius: 3px;
    transition: color 0.15s ease, background-color 0.15s ease;
    &:hover {
      color: $secondary;
      background-color: white;
    }
}

.nav-section-header {
  color: white;
  font-size: 20px;
  text-transform: capitalize;
  margin: 0;
  padding-bottom: 15px;
}

.nav-link__menu {
  min-height: 550px;
  width: 675px;
  background-color: $primary;
  position: absolute;
  top: 8%;
  right: 4%;
  box-shadow: 0 20px 70px rgba(0, 0, 0, 0.562);
  border-radius: 20px;
  opacity: 0;
  transform: translateX(1000px);
  transition: opacity 0.15s ease-in-out, transform 0.3s ease-in-out;
  @include flex(column, flex-start, center);

  .nav-link__menu-backbtn {
    display: none;
    position: absolute;
    top: 2%;
    left: 2%;
    cursor: pointer;
    margin: 0px;
    transition: transform 0.2s ease;

    i {
      color: white;
    }

    p {
      color: white;
      font-size: 16px;
      text-transform: capitalize;
      margin: 0 2px;
    }

    &:hover {
      transform: translateX(-2px);
    }
  }

  h2 {
    font-size: 40px;
    text-align: center;
    font-weight: 200;
    color: white;
    letter-spacing: 2px;
    text-transform: uppercase;
    text-decoration: dotted;
    margin: 15px 0;
  }

  .spacer {
    margin-top: 15px;
  }

  .nav-link {
    margin: 5px 5px;
    color: white;
    font-size: 16px;
    padding: 5px;
    text-transform: capitalize;
    text-align: center;
    border-radius: 3px;
    width: 90%;
    transition: color 0.15s ease, background-color 0.15s ease;
    text-decoration: underline;
    text-decoration-thickness: 1px;
    &:hover {
      color: $secondary;
      background-color: white;
    }
  }

  a {
    //text-decoration: none;
    @include flex(row, center, center);
  }
}

.square1 {
  height: 150px;
  width: 150px;
  background-color: darken($color: $gray, $amount: 30);
  position: absolute;
  top: 4%;
  right: 50%;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.459);
  border-radius: 20px;
  opacity: 0;
  transform: translateX(1000px);
  transition: opacity 0.15s ease-in-out, transform 0.3s ease-in-out;
  @include flex(column, space-between, center);
}

.square2 {
  height: 220px;
  width: 220px;
  background-color: $secondary;
  position: absolute;
  top: 68%;
  right: 1%;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.562);
  border-radius: 20px;
  opacity: 0;
  transform: translateX(1000px);
  transition: opacity 0.15s ease-in-out, transform 0.3s ease-in-out;
  @include flex(column, space-between, center);
}

.square3 {
  height: 175px;
  width: 175px;
  background-color: darken($color: $gray, $amount: 30);
  position: absolute;
  top: 73%;
  right: 48%;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.562);
  border-radius: 20px;
  opacity: 0;
  transform: translateX(1000px);
  transition: opacity 0.15s ease-in-out, transform 0.3s ease-in-out;
  @include flex(column, space-between, center);
}

.square4 {
  height: 100px;
  width: 100px;
  background-color: $secondary;
  position: absolute;
  top: 4%;
  right: 2%;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.397);
  border-radius: 20px;
  opacity: 0;
  transform: translateX(1000px);
  transition: opacity 0.15s ease-in-out, transform 0.3s ease-in-out;
  @include flex(column, space-between, center);
}

.nav-link__menu.move {
  transform: translateX(0px);
  opacity: 1;
}

.square1.move,
.square2.move,
.square3.move,
.square4.move {
  transform: translateX(0px);
  opacity: 1;
}

.nav-bar__menu-img {
  display: none;
  margin-right: 100px;
}

@media screen and (min-width: 1750px) {
  .square1,
  .square3 {
    right: 43%;
  }

  .square4 {
    height: 150px;
    width: 150px;
  }
}

@media screen and (min-width: 1535px) {
  .nav-bar__menu-img {
    display: inline;
  }

  .square1,
  .square3 {
    right: 48%;
  }

  .square4 {
    height: 150px;
    width: 150px;
  }

  .nav-link__menu {
    width: 800px;
    height: 600px;
  }
}

@media screen and (max-width: 1535px) {
  .nav-bar__menu-img {
    display: inline;
    width: 450px;
  }
}

@media screen and (min-width: 1880px) {
  .nav-bar__menu {
    .spacer {
      margin-top: 40px;
    }

    .nav-link {
      font-size: 20px;
    }

    nav {
      .link,
      p {
        font-size: 48px;
      }
    }

    .nav-link__menu {
      top: 12%;
      right: 5%;
      width: 900px;
      height: 700px;

      h2 {
        font-size: 54px;
      }
    }
  }

  .square1,
  .square3 {
    right: 40%;
    height: 300px;
    width: 300px;
  }

  .square2,
  .square4 {
    height: 250px;
    width: 250px;
  }

  .spotlight {
    height: 200px;

    img {
      width: 200px;
    }

    .text-container {
      h3 {
        font-size: 34px;
      }

      p {
        font-size: 20px;
      }
    }
  }
}

@media screen and (min-width: 2460px) {
  .nav-bar {
    padding: 10px 40px;
    height: 90px;

    .btn-group3 {
      #menu-label {
        font-size: 2rem;
      }
    }
  }

  .menu-btn {
    .menu-btn__line {
      width: 50px;
      height: 5px;
    }
  }

  .nav-bar__menu {
    .spacer {
      margin-top: 40px;
    }

    .nav-link {
      font-size: 20px;
    }

    nav {
      .link,
      p {
        font-size: 72px;
      }
    }

    .nav-link__menu {
      top: 15%;
      right: 5%;
      width: 1000px;
      height: 800px;

      h2 {
        font-size: 60px;
      }
    }
  }

  .square1,
  .square3 {
    right: 40%;
    height: 300px;
    width: 300px;
  }

  .square2,
  .square4 {
    height: 250px;
    width: 250px;
  }

  .spotlight {
    height: 200px;

    img {
      width: 220px;
    }

    .text-container {
      h3 {
        font-size: 34px;
      }

      p {
        font-size: 20px;
      }
    }
  }
}

@media screen and (min-width: 3900px) {
  .nav-bar__menu {
    .spacer {
      margin-top: 40px;
    }

    .nav-link {
      font-size: 32px;
      margin: 10px 5px;
    }

    nav {
      .link,
      p {
        font-size: 72px;
      }
    }

    .nav-link__menu {
      top: 15%;
      right: 5%;
      width: 1200px;
      height: 1000px;

      h2 {
        font-size: 68px;
      }
    }
  }

  .square1,
  .square3 {
    right: 32%;
    height: 300px;
    width: 300px;
  }

  .square2,
  .square4 {
    height: 250px;
    width: 250px;
  }

  .spotlight {
    height: 215px;

    img {
      width: 248px;
    }

    .text-container {
      h3 {
        font-size: 38px;
      }

      p {
        font-size: 24px;
      }
    }
  }
}

@media screen and (max-width: 1400px) {
}

@media screen and (max-width: $lap-top-mid) {
  .nav-bar {
    @include grid(1, 2);
    .btn-group2 {
      display: none;
    }
  }

  .nav-bar__menu {
    overflow-y: visible;

    .mobile-nav-btns {
      display: flex;
    }

    nav {
      padding-top: 30px;
      align-items: center;
      flex-grow: 1;

      .link,
      p {
        text-align: center;
        font-size: 30px;
        margin: 12px 5px;
      }

      .spacer-for-line {
        margin-bottom: 5px;
      }

      .link-underline {
        display: none;
      }
    }

    .square1,
    .square2,
    .square3,
    .square4 {
      display: none;
    }
  }

  .nav-link__menu {
    top: 0;
    right: 0;
    min-height: 100%;
    width: 100%;
    border-radius: 0;
    padding-bottom: 40px;
    transform: translateX(1500px);

    .nav-link {
      font-size: 24px;
    }

    .nav-link__menu-backbtn {
      @include flex(row, center, center);
    }
  }
}

@media screen and (max-width: 1073px) {
  .nav-bar__menu-img {
    display: none;
  }
}

@media screen and (max-width: $lap-top-small) {
  .nav-bar {
    @include grid(1, 2);
    .btn-group2 {
      display: none;
    }
  }

  .nav-bar__menu {
    nav {
      .mobile-nav-btns {
        display: flex;
      }
    }
  }

  .nav-link__menu {
    h2 {
      margin-top: 45px;
    }

    .nav-link {
      font-size: 24px;
    }
  }
}

@media screen and (max-width: $tablet-small) {
  .spotlight {
    width: 90%;
  }

  .nav-bar {
    .btn-group1 {
      .nav-btn {
        display: none;
      }
    }
  }
}

@media screen and (max-width: $phone-large) {
  .nav-bar {
    padding: 0px 10px;
    .btn-group3 {
      #menu-label {
        font-size: 1rem;
      }
    }
  }

  .nav-bar__menu {
    nav {
      padding-bottom: 85px;
    }

    a {
      font-size: 20px;
    }
    .nav-link {
      font-size: 12px;
    }
  }

  .spotlight {
    width: 95%;
    height: 150px;

    img {
      width: 140px;
    }

    .text-container {
      align-items: center;
      h3 {
        font-size: 18px;
        text-align: center;
      }

      p {
        font-size: 12px;
        line-height: 14px;
        text-align: center;
      }
    }
  }
}

@media screen and (max-width: $phone) {
  .spotlight {
    .text-container {
      margin-left: 0;
    }
  }
}

@media screen and (max-width: $phone-small) {
  .menu-btn {
    .menu-btn__line {
      width: 30px;
      height: 3px;
    }
  }

  .menu-btn.close {
    .menu-btn__line:nth-child(1) {
      transform: rotate(45deg) translate(5px, 6.5px);
    }

    .menu-btn__line:nth-child(3) {
      transform: rotate(-45deg) translate(5px, -6.5px);
    }
  }

  .nav-bar {
    .nav-btn {
      width: auto;
    }

    .btn-group3 {
      #menu-label {
        margin-right: 3px;
      }
    }
  }
}
