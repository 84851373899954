.ease-grant-header {
  background-image: url(../img/Ease-grant-background.webp);
  background-position: center;

  .ease-grant-header__text {
    padding-left: 30px;
  }
}

@media screen and (max-width: 1024px) {
  .ease-grant-header {
    .ease-grant-header__text {
      padding-left: 0;
      padding: 10px 15px;
    }
  }
}
