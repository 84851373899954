a {
  text-decoration: none;
}

.block-link {
  position: relative;
  background-color: $secondary;
  background-position: center;
  background-size: cover;
  margin: 20px 20px;
  padding: 5px 15px;
  width: 250px;
  height: 200px;
  border-radius: 25px;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.63);
  text-align: center;
  transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;

  p {
    text-decoration: none;
    color: white;
    font-weight: bold;
    font-size: 18px;
    text-shadow: 3px 3px 5px black;
    letter-spacing: 2px;
  }

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
  }

  &:hover {
    box-shadow: 0 30px 60px rgba(0, 0, 0, 0.308);
    transform: scale(1.05);
  }
}
