.header-text-container {
  position: relative;
  text-align: center;
  width: 70%;
  margin: 0 auto;
  min-height: 275px;
  @include flex(column, center, center);
}

//squircle decoration
.squircle-small-blue {
  width: 220px;
  height: 220px;
  position: absolute;
  right: -25%;
  top: 20%;
  background-color: $primary;
  border-radius: 15px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.507);
}

.squircle-small-red {
  width: 70px;
  height: 70px;
  position: absolute;
  top: 5%;
  right: -18%;
  background-color: $secondary;
  border-radius: 30px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.507);
}

.squircle-large-blue {
  width: 250px;
  height: 250px;
  position: absolute;
  top: 2%;
  right: -18%;
  background-color: $secondary;
  border-radius: 25px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.507);
}

.squircle-large-red {
  width: 200px;
  height: 200px;
  position: absolute;
  left: -23%;
  bottom: 10%;
  background-color: $secondary;
  border-radius: 25px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.507);
}

@media screen and (max-width: $lap-top-mid) {
  .squircle-small-blue {
    width: 125px;
    height: 125px;
  }

  .squircle-large-red {
    width: 150px;
    height: 150px;
  }
}

@media screen and (max-width: $lap-top-small) {
  .squircle-small-blue {
    width: 125px;
    height: 125px;
  }

  .squircle-large-red {
    left: -30%;
    width: 150px;
    height: 150px;
  }
}

@media screen and (max-width: $tablet-large) {
  .squircle-small-blue {
    width: 125px;
    height: 125px;
  }

  .squircle-large-red {
    left: -30%;
    width: 150px;
    height: 150px;
  }
}

@media screen and (max-width: $tablet-small) {
  .squircle-small-blue {
    right: -35%;
    width: 125px;
    height: 125px;
  }

  .squircle-large-red {
    width: 150px;
    height: 150px;
    left: -38%;
  }
}

@media screen and (max-width: $phone-large) {
  .header-text-container {
    width: 95%;
  }

  .text-container {
    width: 90%;
  }

  .squircle-small-blue {
    display: none;
  }

  .squircle-large-red {
    display: none;
  }
}
