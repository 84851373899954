footer {
  @include flex(column, center, center);
  overflow: hidden;
  padding-bottom: 50px;

  h2 {
    font-size: 48px;
    font-weight: 300;
    text-align: center;
    letter-spacing: 2px;
  }

  h3 {
    color: $primary;
    letter-spacing: 1.5px;
  }
}

.featured-links {
  width: 100%;

  .links {
    padding: 0 30px;
    margin: 40px 0px 25px;
  }

  .link {
    justify-self: center;
    align-self: center;
    @include flex(column, center, center);
    width: 130px;
    height: 130px;
    color: white;
    margin: 20px;
    border-radius: 25px;
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.507);
    background-color: $primary;
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;

    &:hover {
      transform: scale(1.1);
      box-shadow: 0 20px 60px rgba(0, 0, 0, 0.322);
    }

    a {
      color: white;
      text-align: center;
      text-decoration: none;
      line-height: 22px;
      font-weight: 700;
      padding: 0 8px;
    }
  }

  .link:nth-of-type(even) {
    background-color: $secondary;
  }
}

.footer-bottom {
  padding-top: 30px;
}

.footer-text-container {
  text-align: center;
}

.social-links {
  .social-link {
    margin: 5px 15px;
    padding: 10px;
    width: 65px;
    background-color: white;
    border: 2px solid $primary;
    transition: border 0.3s ease-in-out, background-color 0.3s ease-in-out;

    i {
      color: $primary;
      font-size: 32px;
      transition: color 0.3s ease-in-out;
    }
  }

  .social-link:hover {
    border: 2px solid $secondary;
    background-color: $secondary;
    i {
      color: white;
    }
  }
}

.translator {
  @include flex(column, center, center);
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.445);
  border-radius: 15px;
  padding: 10px 30px;
  width: 80%;
  max-width: 320px;

  h3 {
    text-align: center;
    font-size: 40px;
    margin-bottom: 0;
    margin-top: 10px;
    font-weight: 300;
  }

  h4 {
    color: $primary;
  }

  select {
    outline: 0;
    border: 2px solid $primary;
    border-radius: 20px;
    padding: 3px 5px;
    margin-bottom: 15px;
  }
}

.amazon-link {
  cursor: pointer;
  background-color: $primary;
  border-radius: 25px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.486);
  padding: 10px;
  margin: 15px 0;
  transition: transform 0.2s ease-in-out;
  max-width: 300px;

  &:hover {
    transform: scale(1.05);
  }
}

.copyright {
  background: linear-gradient(180deg, white 0%, $secondary 5%);

  p {
    padding: 30px 15px 75px 15px;
    color: white;
    font-size: 12px;
    text-align: center;
  }
}

@media screen and (max-width: 1225px) {
  .featured-links {
    .links {
      .link {
        width: 120px;
        height: 120px;
      }
    }
  }

  .translator {
    margin: 60px 10px 80px;
  }
}

@media screen and (max-width: $lap-top-small) {
  .featured-links {
    .links {
      @include grid(3, 2, 20px);
    }
  }

  .translator {
    margin: 60px 10px 80px;
  }
}

@media screen and (max-width: $phone-large) {
  .translator {
    padding: 10px 0px;
  }
}

@media screen and (max-width: $phone) {
  .featured-links {
    .link {
      width: 120px;
      height: 120px;

      a {
        font-size: 14px;
      }
    }
  }

  .amazon-link {
    margin: 15px 0;
  }
}
