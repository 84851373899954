.info-page-header {
  margin: 0 auto;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 80px;
  background-image: linear-gradient(180deg, transparent 75%, white 100%),
    url(../img/header.jpg);
  background-position: center;
  background-repeat: repeat;

  .header-text-container {
    min-height: 500px;

    .top-heading {
      color: white;
      text-shadow: 1px 0px 12px black, -1px 0px 12px black, 0px 1px 12px black, 0px -1px 12px black;
    }

    .sub-heading {
      color: white;
      text-shadow: 1px 0px 12px black, -1px 0px 12px black, 0px 1px 12px black, 0px -1px 12px black;
    }

    p {
      color: white;
      text-shadow: 3px 3px 5px black;
    }

    .inline-link {
      &:hover {
        color: white;
      }
    }

    .squircle-small-blue {
      background-color: white;
    }
  }
}

@media screen and (max-width: 1100px) {
  .info-page-header {
    background-image: linear-gradient(180deg, transparent 75%, white 100%),
      url(../img/header.jpg);
  }
}
