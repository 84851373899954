//variables
$primary: #002149;
$primary-shadow: #0021496c;
$secondary: #e4002b;
$secondary-shadow: #e4002a70;
$gray: #f2f2f2;

*,
*::before,
*::after {
  box-sizing: border-box;
}

//typography

*::selection {
  background-color: $secondary;
  color: white;
}

body {
  font-family: "Montserrat", sans-serif;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
p {
  padding: 0 5px;
  color: $primary;
}

p {
  color: $primary;
  line-height: 1.75;
}

.text-center {
  text-align: center;

  & > * {
    text-align: center;
  }
}

.text-block {
  max-width: 1000px;
  width: 90%;
}

.top-heading {
  font-size: 60px;
  font-weight: 200;
  text-align: center;
  color: $primary;
  margin-bottom: 0;
  margin-top: 25px;
  text-transform: capitalize;
  letter-spacing: 1px;
}

.top-heading-left {
  @extend .top-heading;
  text-align: left;
}

.sub-heading {
  color: $primary;
  font-size: 36px;
  font-weight: 500;
  margin-top: 10px;
  text-align: center;
}

.sub-heading-small {
  @extend .sub-heading;
  font-size: 24px;
}

.sub-heading-left {
  @extend .sub-heading;
  text-align: left;
}

.sub-heading-small-left {
  @extend .sub-heading;
  font-size: 24px;
  text-align: left;
}

.cta {
  font-size: 1.2rem;
  line-height: 2rem;
  text-align: center;
}

.color-white {
  color: white;
}

.color-black {
  color: black;
}

//links

.no-dec {
  text-decoration: none;
}

.inline-link {
  font-weight: bold;
  color: $secondary;
  text-decoration: none;
  transition: color 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    color: $primary;
  }
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.underline {
  text-decoration: underline;
}

//lists
.color-secondary {
  color: $secondary;
}

ul {
  padding-left: 40px;
}

li {
  line-height: 1.5;
  color: $primary;
}

.styled-list {
  li {
    color: $primary;
    font-weight: bold;
    list-style: none;
    margin-bottom: 10px;
  }
}

//images

.img-break {
  max-height: 600px;
  overflow: hidden;
  img {
    position: relative;
    margin: 0;
    z-index: -1;
    box-shadow: 0 0 30px 10px rgba(0, 0, 0, 0.459);
  }
}

.width-300 {
  width: 280px;
}
.img-width-contain {
  max-width: 400px;
}

.top-banner-img {
  margin: 0;
  margin-bottom: 25px;
  box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.486);
}

img {
  width: 100%;
  margin: 15px 0;
}

.badge-large {
  width: 400px;
}

.badge-med {
  width: 300px;
}

.badge-small {
  width: 150px;
  margin: 0 20px;
}

.badge-glide {
  width: 150px;
}

@media screen and (max-width: $phone-large) {
  .badge-small,
  .badge-large {
    width: 280px;
  }
}

@media screen and (max-width: $phone) {
  .badge-small,
  .badge-large {
    width: 240px;
  }
}

//sections
.block {
  padding: 30px 60px;
  margin: 0 auto;
  max-width: 1300px;
}

.block-max {
  padding: 30px 60px;
  margin: 0 auto;
}

.block-flipsnack {
  padding: 30px 60px;
  margin: 0 auto;
  width: 100%;
}

.block-large {
  padding: 100px 0px 30px;
  margin-bottom: 60px;
}

//buttons

.nav-btn {
  outline: none;
  border: 0;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 1px;
  min-width: 146px;
  height: 32px;
  margin: 5px;
  border-radius: 5px;
  text-align: center;
  @include flex(column, center, center);
}

.btn {
  cursor: pointer;
  outline: none;
  border: 0;
  font-size: 2rem;
  letter-spacing: 1px;
  text-decoration: none;
  text-transform: capitalize;
  text-align: center;
  @include flex(column, center, center);
  min-width: 250px;
  padding-right: 10px;
  padding-left: 10px;
  min-height: 60px;
  border-radius: 30px;
  margin: 20px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.507);

  i {
    font-size: 2rem;
    margin-right: 25px;
  }
}

.btn__small-text {
  @extend .btn;
  font-size: 1.2rem;
  font-weight: bold;

  i {
    font-size: 1.5rem;
    margin-right: 10px;
  }
}

.btn-primary {
  color: white;
  background: $primary;
  border: 2px solid $primary;
  transition: background-color 0.2s ease, color 0.2s ease;

  &:hover {
    color: $primary;
    background-color: white;
  }
}

.btn-secondary {
  color: white;
  background: $secondary;
  border: 2px solid $secondary;
  transition: background-color 0.2s ease, color 0.2s ease;

  &:hover {
    color: $secondary;
    background-color: white;
  }

  &.active {
    color: $secondary;
    background-color: white;
  }
}

.btn-outline {
  color: $primary;
  background: white;
  border: 2px solid $primary;
  transition: background-color 0.2s ease, color 0.2s ease;

  &:hover {
    color: $secondary;
    background-color: white;
  }
}
//button group

.btn-group {
  @include flex(row, center, center);
}

.btn-container {
  @extend .flex-column;
}

//grids

.grid-1 {
  @include grid(1, 1, 10px);
}

.grid-2-col {
  @include grid(1, 2, 0);
}

.grid-3-col {
  @include grid(1, 3, 10px);
  width: 95%;
}

.grid-4-col {
  @include grid(1, 4, 0);
  width: 100%;
  padding: 30px 0;
}

.grid-1 > *,
.grid-2-col > *,
.grid-3-col > *,
.grid-4-col > * {
  justify-self: center;
  align-self: flex-start;
  margin: 15px;
  width: 95%;
}

.grid-2-row {
  @include grid(2, 1, 0);
  width: 100%;
}

.grid-2-row > * {
  justify-self: center;
  align-self: center;
}

.align-center {
  align-self: center;
}

.grid-border {
  border: 1px solid $primary;
}

.no-gap {
  gap: 0;
}

@media screen and (max-width: 1100px) {
  .grid-2-col,
  .grid-3-col,
  .grid-4-col {
    @include grid(1, 1, 0);
  }

  .grid-1 > *,
  .grid-2-col > *,
  .grid-3-col > *,
  .grid-4-col > * {
    justify-self: center;
    align-self: flex-start;
    margin: 10px;
  }
}

.gap {
  gap: 50px;
}

//flex

.flex-column {
  @include flex(column, center, center);
}

.flex-column-start {
  @include flex(column, center, flex-start);
}

.flex-column-end {
  @include flex(column, end, center);
}

.flex-column-just-start {
  @include flex(column, flex-start, flex-start);
}

.flex-column-between {
  @include flex(column, space-between, center);
}

.flex-row {
  @include flex(row, center, center);
  flex-wrap: wrap;
}

.flex-row-between {
  @include flex(row, space-between, center);
  flex-wrap: wrap;
}

.flex-row-around {
  @include flex(row, space-around, center);
  flex-wrap: wrap;
}

.flex-row-start {
  @include flex(row, center, flex-start);
  flex-wrap: wrap;
}

.flex-row-just-start {
  @include flex(row, flex-start, center);
  flex-wrap: wrap;
}

@media screen and (max-width: $lap-top-small) {
}

//tables

.responsive-table-container {
  width: 100%;
  overflow-x: auto;
  padding: 15px 0 55px;
}

table {
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.479);
  min-width: 260px;
  width: 85%;
  margin: 15px auto 10px;
  border-collapse: collapse;
}

thead {
  font-size: 20px;
  letter-spacing: 1.5px;
  margin: 10px;
  border-bottom: 2px solid $primary;

  td {
    border: 2px solid $primary;
    padding: 7px 5px;
    color: $primary;
    font-weight: 500;
  }

  h2 {
    margin-top: 10px;
    margin-bottom: 15px;
  }

  &.center {
    td {
      text-align: center;
    }
  }
}

tbody {
  border: 2px solid $primary;

  td {
    height: 35px;
    border: 1px solid $primary;
    padding: 7px 12px;
    color: $primary;

    a {
      margin: 10px 5px;
      color: $secondary;
      text-decoration: none;
      font-weight: bold;
    }

    &:nth-of-type(even) {
      background-color: $primary;
      color: white;
      border-bottom: 1px solid white;
    }
  }

  &.center {
    td {
      text-align: center;
    }
  }
}

//spacing
.spacer-top {
  padding-top: 30px;
}

.spacer-bottom {
  padding-bottom: 30px;
}

//collapsible
.collapsible {
  padding: 0 5px;
  max-height: 75px;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
  
  &.zero{
    max-height: 0px;
  }

  .collapsible__notifier {
    border: 3px solid #002149;
    border-radius: 25px;
    max-width: 900px;
    padding: 10px 15px;
    cursor: pointer;
    margin: 0 auto;
    transition: all 0.3s ease-in-out;

    .sub-heading {
      margin: 0;
    }
  }

  li {
    width: 100%;
    padding: 5px 10px;
    border-bottom: 2px solid white;
  }

  &.expanded {
    max-height: fit-content;

    .collapsible__notifier {
      background-color: $secondary;
      border: 3px solid white;

      .top-heading {
        color: white;
      }
    }
  }

}

.expanded {
  .collapsible {
    max-height: 100vh;
  }
}

@media screen and (max-width: 618px) {
  .collapsible {
    max-height: 77px;
  }
}

//contact block

.contact-block {
  width: 90%;
  padding: 20px 5px;
  background-color: #002149;
  border-radius: 30px;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.466);

  h2 {
    color: white;
    text-shadow: 3px 3px 5px black;
  }
  h3 {
    color: white;
    padding-top: 10px;
  }
  p {
    color: white;
    margin: 5px;
  }

  .inline-link {
    &:hover {
      color: white;
    }
  }
}

//site wide search styles

.site-search-results {
  table {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.438);
  }
}

.site-search-bar {
  padding: 3px 5px;
  border-radius: 25px;
  flex-wrap: nowrap;
  background-color: white;
  margin-right: 10px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.445);

  form {
    flex-wrap: nowrap;
  }

  input {
    border: 0;
    outline: 0;
    color: $primary;
    font-size: 0.9rem;
    width: 100%;
  }

  &__search-btn {
    border: 0;
    outline: 0;
    background-color: $secondary;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    box-shadow: 0 0px 5px rgba(0, 0, 0, 0.404);
    cursor: pointer;
    transition: all 0.2s ease;

    i {
      color: white;
      font-size: 0.9rem;
    }
  }

  &__search-btn:hover {
    transform: scale(1.05);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.404);
  }
}

.mobile-site-search-bar {
  width: 95%;
  margin-bottom: 10px;
  display: none;

  .site-search-bar__search-btn {
    width: 20%;
    min-width: 55px;
    border-radius: 25px;
  }

  @extend .site-search-bar;
}

@media screen and (max-width: 768px) {
  .site-search-bar {
    display: none;
  }

  .mobile-site-search-bar {
    display: block;
  }
}

//utility

.line {
  width: 90%;
  height: 3px;
  margin: 30px auto;
  background-image: radial-gradient(
    circle,
    $primary 0%,
    rgba(255, 0, 155, 0) 100%
  );
}

.verticle-line {
  width: 3px;
  height: 90%;
  margin: 10px 20px;
  background-image: radial-gradient(
    circle,
    $primary 0%,
    rgba(255, 0, 155, 0) 100%
  );
}

.blue-break {
  background: $primary;
  width: 100%;
  height: 40px;
  box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.459);
}

.stop-scroll {
  overflow-y: hidden;
}

.width-95 {
  min-width: 95%;
}

.width-100 {
  max-width: 100px;
}

.width-200 {
  max-width: 200px;
}

.width-300 {
  max-width: 300px;
}

.width-400 {
  max-width: 400px;
}

.width-500 {
  max-width: 500px;
}

.width-600 {
  max-width: 600px;
}

.height-stretch {
  height: 100%;
}

.width-stretch {
  width: 100%;
}

.overflow-hidden {
  overflow: hidden;
}

.op-zero {
  opacity: 0;
}

.margin-center {
  margin: 0 auto;
}

.margin-top {
  margin-top: 20px;
}

.margin-zero {
  margin: 0;
}

.margin-25 {
  margin: 25px;
}

.margin-15 {
  margin: 15px;
}

.position-relative {
  position: relative;
}

.display-none {
  display: none;
}

.hide-container-edge {
  overflow-x: hidden;
  width: 100%;
}

//flipsnack

//copyright

.copywrite-container {
  background: $secondary;

  .copywrite {
    color: white;
    font-size: 12px;
    line-height: 1.5;
    padding: 10px 20px 40px;
    text-align: center;
  }
}

//privacy policy

.privacy-policy {
  width: 95%;

  h1 {
    text-align: center;
  }
}

//iframes

.iframe-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 800px;
  margin: 15px auto;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.486);
  border-radius: 20px;
}

/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

//squircles
.make-squircle {
  border-radius: 45px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.486);

  &-blue {
    border-radius: 45px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.486);
    padding: 5px;
    background-color: $primary;

    p,
    h3 {
      color: white;
    }
  }
}

.squircle-bg {
  background-color: $primary;
  max-width: 300px;
  min-width: 300px;
  height: 300px;
  border-radius: 25px;
  padding: 10px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.486);
  margin: 10px;

  h3,
  h4,
  p,
  li {
    color: white;
    line-height: 20px;
  }

  .btn {
    min-height: 60px;
  }

  img {
    width: 100%;
    border-radius: 20px;
    margin: 0;
    box-shadow: 0 10px 35px rgba(0, 0, 0, 0.623);
  }
}

.squircle-bg-large {
  background-color: $primary;
  border-radius: 25px;
  padding: 10px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.486);
  margin: 10px;
  max-width: 90%;
  height: auto;

  h3,
  h4,
  p,
  li {
    color: white;
    line-height: 1.25;
  }
}

@media screen and (max-width: 680px) {
  .squircle-bg {
    margin: 10px 0;
    height: auto;
  }

  .flex-column-start {
    align-items: center;
  }
}

//scrollbar

.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

//new site welcome

// .new-site-welcome {
//   position: fixed;
//   z-index: 10;
//   left: 0;
//   bottom: 5px;
//   border-radius: 0 20px 20px 0;
//   max-width: 50%;
//   background-color: $secondary;
//   box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.548);
//   transform: translatex(-1000px);

//   h1 {
//     margin-left: 5px;
//     font-size: 20px;
//     color: white;
//     letter-spacing: 1.5px;
//   }

//   img {
//     width: 50px;
//     margin: 10px 20px;
//   }
// }

@media screen and (min-width: 2460px) {
  .nav-btn {
    height: 50px;
    font-size: 1.5rem;
    min-width: 200px;
    padding: 0 10px;
  }
}

@media screen and (max-width: $lap-top-small) {
  .flex-column-start {
    @include flex(column, center, center);
  }

  .top-heading {
    font-size: 40px;
    font-weight: 300;
  }

  .sub-heading {
    font-size: 26px;
    font-weight: 300;
  }

  h1,
  h2,
  h3,
  h4,
  p {
    padding: 0 5px;
    color: $primary;
    text-align: center;
  }
}

@media screen and (max-width: $tablet-large) {
  .top-heading {
    font-size: 40px;
    font-weight: 300;
  }

  .sub-heading {
    font-size: 26px;
    font-weight: 300;
  }

  .block {
    padding: 30px 10px;
  }
}

@media screen and (max-width: $phone-large) {
  .top-heading {
    font-size: 34px;
    font-weight: 300;
  }

  .sub-heading {
    font-size: 20px;
    font-weight: 300;
  }

  .block-max {
    padding: 60px 0;
  }
}
