.transfer-portal {
  border-radius: 25px;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.425);
  display: flex;
  width: 100%;

  .content {
    flex-basis: 50%;
    padding: 10px 20px;
  }

  img {
    width: 0;
    margin: 0;
    flex-basis: 50%;
    border-radius: 0 25px 25px 0;
  }
}

@media screen and (max-width: 1150px) {
  .transfer-portal {
    flex-wrap: wrap;

    .content {
      flex-basis: auto;
    }

    img {
      flex-basis: auto;
      width: 100%;
      border-radius: 0 0 25px 25px;
    }
  }
}

@media screen and (max-width: 450px) {
  .transfer-portal {
    .content {
      padding: 15px 5px;

      .btn__small-text {
        margin: 20px 0;
      }
    }
  }
}
