html {
  line-height: 1.15;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

a {
  background-color: transparent;
}

abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}

button,
input {
  /* 1 */
  overflow: visible;
}

button,
select {
  /* 1 */
  text-transform: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  box-sizing: border-box;
  /* 1 */
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  white-space: normal;
  /* 1 */
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type=checkbox],
[type=radio] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

details {
  display: block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

*::selection {
  background-color: #e4002b;
  color: white;
}

body {
  font-family: "Montserrat", sans-serif;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
p {
  padding: 0 5px;
  color: #002149;
}

p {
  color: #002149;
  line-height: 1.75;
}

.text-center {
  text-align: center;
}

.text-center > * {
  text-align: center;
}

.text-block {
  max-width: 1000px;
  width: 90%;
}

.top-heading, .top-heading-left {
  font-size: 60px;
  font-weight: 200;
  text-align: center;
  color: #002149;
  margin-bottom: 0;
  margin-top: 25px;
  text-transform: capitalize;
  letter-spacing: 1px;
}

.top-heading-left {
  text-align: left;
}

.sub-heading, .sub-heading-small-left, .sub-heading-left, .sub-heading-small {
  color: #002149;
  font-size: 36px;
  font-weight: 500;
  margin-top: 10px;
  text-align: center;
}

.sub-heading-small {
  font-size: 24px;
}

.sub-heading-left {
  text-align: left;
}

.sub-heading-small-left {
  font-size: 24px;
  text-align: left;
}

.cta {
  font-size: 1.2rem;
  line-height: 2rem;
  text-align: center;
}

.color-white {
  color: white;
}

.color-black {
  color: black;
}

.no-dec {
  text-decoration: none;
}

.inline-link {
  font-weight: bold;
  color: #e4002b;
  text-decoration: none;
  transition: color 0.2s ease-in-out;
  cursor: pointer;
}

.inline-link:hover {
  color: #002149;
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.underline {
  text-decoration: underline;
}

.color-secondary {
  color: #e4002b;
}

ul {
  padding-left: 40px;
}

li {
  line-height: 1.5;
  color: #002149;
}

.styled-list li {
  color: #002149;
  font-weight: bold;
  list-style: none;
  margin-bottom: 10px;
}

.img-break {
  max-height: 600px;
  overflow: hidden;
}

.img-break img {
  position: relative;
  margin: 0;
  z-index: -1;
  box-shadow: 0 0 30px 10px rgba(0, 0, 0, 0.459);
}

.width-300 {
  width: 280px;
}

.img-width-contain {
  max-width: 400px;
}

.top-banner-img {
  margin: 0;
  margin-bottom: 25px;
  box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.486);
}

img {
  width: 100%;
  margin: 15px 0;
}

.badge-large {
  width: 400px;
}

.badge-med {
  width: 300px;
}

.badge-small {
  width: 150px;
  margin: 0 20px;
}

.badge-glide {
  width: 150px;
}

@media screen and (max-width: 550px) {
  .badge-small,
.badge-large {
    width: 280px;
  }
}

@media screen and (max-width: 475px) {
  .badge-small,
.badge-large {
    width: 240px;
  }
}

.block {
  padding: 30px 60px;
  margin: 0 auto;
  max-width: 1300px;
}

.block-max {
  padding: 30px 60px;
  margin: 0 auto;
}

.block-flipsnack {
  padding: 30px 60px;
  margin: 0 auto;
  width: 100%;
}

.block-large {
  padding: 100px 0px 30px;
  margin-bottom: 60px;
}

.nav-btn {
  outline: none;
  border: 0;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 1px;
  min-width: 146px;
  height: 32px;
  margin: 5px;
  border-radius: 5px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.btn, .btn__small-text {
  cursor: pointer;
  outline: none;
  border: 0;
  font-size: 2rem;
  letter-spacing: 1px;
  text-decoration: none;
  text-transform: capitalize;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 250px;
  padding-right: 10px;
  padding-left: 10px;
  min-height: 60px;
  border-radius: 30px;
  margin: 20px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.507);
}

.btn i, .btn__small-text i {
  font-size: 2rem;
  margin-right: 25px;
}

.btn__small-text {
  font-size: 1.2rem;
  font-weight: bold;
}

.btn__small-text i {
  font-size: 1.5rem;
  margin-right: 10px;
}

.btn-primary {
  color: white;
  background: #002149;
  border: 2px solid #002149;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.btn-primary:hover {
  color: #002149;
  background-color: white;
}

.btn-secondary {
  color: white;
  background: #e4002b;
  border: 2px solid #e4002b;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.btn-secondary:hover {
  color: #e4002b;
  background-color: white;
}

.btn-secondary.active {
  color: #e4002b;
  background-color: white;
}

.btn-outline {
  color: #002149;
  background: white;
  border: 2px solid #002149;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.btn-outline:hover {
  color: #e4002b;
  background-color: white;
}

.btn-group {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.grid-1 {
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  grid-template-columns: repeat(1, 1fr);
  gap: 10px;
}

.grid-2-col {
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  grid-template-columns: repeat(2, 1fr);
  gap: 0;
}

.grid-3-col {
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  width: 95%;
}

.grid-4-col {
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  grid-template-columns: repeat(4, 1fr);
  gap: 0;
  width: 100%;
  padding: 30px 0;
}

.grid-1 > *,
.grid-2-col > *,
.grid-3-col > *,
.grid-4-col > * {
  justify-self: center;
  align-self: flex-start;
  margin: 15px;
  width: 95%;
}

.grid-2-row {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(1, 1fr);
  gap: 0;
  width: 100%;
}

.grid-2-row > * {
  justify-self: center;
  align-self: center;
}

.align-center {
  align-self: center;
}

.grid-border {
  border: 1px solid #002149;
}

.no-gap {
  gap: 0;
}

@media screen and (max-width: 1100px) {
  .grid-2-col,
.grid-3-col,
.grid-4-col {
    display: grid;
    grid-template-rows: repeat(1, 1fr);
    grid-template-columns: repeat(1, 1fr);
    gap: 0;
  }

  .grid-1 > *,
.grid-2-col > *,
.grid-3-col > *,
.grid-4-col > * {
    justify-self: center;
    align-self: flex-start;
    margin: 10px;
  }
}

.gap {
  gap: 50px;
}

.flex-column, .btn-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flex-column-start {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.flex-column-end {
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
}

.flex-column-just-start {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-column-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.flex-row-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.flex-row-around {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.flex-row-start {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
}

.flex-row-just-start {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.responsive-table-container {
  width: 100%;
  overflow-x: auto;
  padding: 15px 0 55px;
}

table {
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.479);
  min-width: 260px;
  width: 85%;
  margin: 15px auto 10px;
  border-collapse: collapse;
}

thead {
  font-size: 20px;
  letter-spacing: 1.5px;
  margin: 10px;
  border-bottom: 2px solid #002149;
}

thead td {
  border: 2px solid #002149;
  padding: 7px 5px;
  color: #002149;
  font-weight: 500;
}

thead h2 {
  margin-top: 10px;
  margin-bottom: 15px;
}

thead.center td {
  text-align: center;
}

tbody {
  border: 2px solid #002149;
}

tbody td {
  height: 35px;
  border: 1px solid #002149;
  padding: 7px 12px;
  color: #002149;
}

tbody td a {
  margin: 10px 5px;
  color: #e4002b;
  text-decoration: none;
  font-weight: bold;
}

tbody td:nth-of-type(even) {
  background-color: #002149;
  color: white;
  border-bottom: 1px solid white;
}

tbody.center td {
  text-align: center;
}

.spacer-top {
  padding-top: 30px;
}

.spacer-bottom {
  padding-bottom: 30px;
}

.collapsible {
  padding: 0 5px;
  max-height: 75px;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
}

.collapsible.zero {
  max-height: 0px;
}

.collapsible .collapsible__notifier {
  border: 3px solid #002149;
  border-radius: 25px;
  max-width: 900px;
  padding: 10px 15px;
  cursor: pointer;
  margin: 0 auto;
  transition: all 0.3s ease-in-out;
}

.collapsible .collapsible__notifier .sub-heading, .collapsible .collapsible__notifier .sub-heading-small, .collapsible .collapsible__notifier .sub-heading-left, .collapsible .collapsible__notifier .sub-heading-small-left {
  margin: 0;
}

.collapsible li {
  width: 100%;
  padding: 5px 10px;
  border-bottom: 2px solid white;
}

.collapsible.expanded {
  max-height: fit-content;
}

.collapsible.expanded .collapsible__notifier {
  background-color: #e4002b;
  border: 3px solid white;
}

.collapsible.expanded .collapsible__notifier .top-heading, .collapsible.expanded .collapsible__notifier .top-heading-left {
  color: white;
}

.expanded .collapsible {
  max-height: 100vh;
}

@media screen and (max-width: 618px) {
  .collapsible {
    max-height: 77px;
  }
}

.contact-block {
  width: 90%;
  padding: 20px 5px;
  background-color: #002149;
  border-radius: 30px;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.466);
}

.contact-block h2 {
  color: white;
  text-shadow: 3px 3px 5px black;
}

.contact-block h3 {
  color: white;
  padding-top: 10px;
}

.contact-block p {
  color: white;
  margin: 5px;
}

.contact-block .inline-link:hover {
  color: white;
}

.site-search-results table {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.438);
}

.site-search-bar, .mobile-site-search-bar {
  padding: 3px 5px;
  border-radius: 25px;
  flex-wrap: nowrap;
  background-color: white;
  margin-right: 10px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.445);
}

.site-search-bar form, .mobile-site-search-bar form {
  flex-wrap: nowrap;
}

.site-search-bar input, .mobile-site-search-bar input {
  border: 0;
  outline: 0;
  color: #002149;
  font-size: 0.9rem;
  width: 100%;
}

.site-search-bar__search-btn {
  border: 0;
  outline: 0;
  background-color: #e4002b;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  box-shadow: 0 0px 5px rgba(0, 0, 0, 0.404);
  cursor: pointer;
  transition: all 0.2s ease;
}

.site-search-bar__search-btn i {
  color: white;
  font-size: 0.9rem;
}

.site-search-bar__search-btn:hover {
  transform: scale(1.05);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.404);
}

.mobile-site-search-bar {
  width: 95%;
  margin-bottom: 10px;
  display: none;
}

.mobile-site-search-bar .site-search-bar__search-btn {
  width: 20%;
  min-width: 55px;
  border-radius: 25px;
}

@media screen and (max-width: 768px) {
  .site-search-bar, .mobile-site-search-bar {
    display: none;
  }

  .mobile-site-search-bar {
    display: block;
  }
}

.line {
  width: 90%;
  height: 3px;
  margin: 30px auto;
  background-image: radial-gradient(circle, #002149 0%, rgba(255, 0, 155, 0) 100%);
}

.verticle-line {
  width: 3px;
  height: 90%;
  margin: 10px 20px;
  background-image: radial-gradient(circle, #002149 0%, rgba(255, 0, 155, 0) 100%);
}

.blue-break {
  background: #002149;
  width: 100%;
  height: 40px;
  box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.459);
}

.stop-scroll {
  overflow-y: hidden;
}

.width-95 {
  min-width: 95%;
}

.width-100 {
  max-width: 100px;
}

.width-200 {
  max-width: 200px;
}

.width-300 {
  max-width: 300px;
}

.width-400 {
  max-width: 400px;
}

.width-500 {
  max-width: 500px;
}

.width-600 {
  max-width: 600px;
}

.height-stretch {
  height: 100%;
}

.width-stretch {
  width: 100%;
}

.overflow-hidden {
  overflow: hidden;
}

.op-zero {
  opacity: 0;
}

.margin-center {
  margin: 0 auto;
}

.margin-top {
  margin-top: 20px;
}

.margin-zero {
  margin: 0;
}

.margin-25 {
  margin: 25px;
}

.margin-15 {
  margin: 15px;
}

.position-relative {
  position: relative;
}

.display-none {
  display: none;
}

.hide-container-edge {
  overflow-x: hidden;
  width: 100%;
}

.copywrite-container {
  background: #e4002b;
}

.copywrite-container .copywrite {
  color: white;
  font-size: 12px;
  line-height: 1.5;
  padding: 10px 20px 40px;
  text-align: center;
}

.privacy-policy {
  width: 95%;
}

.privacy-policy h1 {
  text-align: center;
}

.iframe-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 800px;
  margin: 15px auto;
  padding-top: 56.25%;
  /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.486);
  border-radius: 20px;
}

/* Then style the iframe to fit in the container div with full height and width */

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.make-squircle {
  border-radius: 45px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.486);
}

.make-squircle-blue {
  border-radius: 45px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.486);
  padding: 5px;
  background-color: #002149;
}

.make-squircle-blue p,
.make-squircle-blue h3 {
  color: white;
}

.squircle-bg {
  background-color: #002149;
  max-width: 300px;
  min-width: 300px;
  height: 300px;
  border-radius: 25px;
  padding: 10px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.486);
  margin: 10px;
}

.squircle-bg h3,
.squircle-bg h4,
.squircle-bg p,
.squircle-bg li {
  color: white;
  line-height: 20px;
}

.squircle-bg .btn, .squircle-bg .btn__small-text {
  min-height: 60px;
}

.squircle-bg img {
  width: 100%;
  border-radius: 20px;
  margin: 0;
  box-shadow: 0 10px 35px rgba(0, 0, 0, 0.623);
}

.squircle-bg-large {
  background-color: #002149;
  border-radius: 25px;
  padding: 10px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.486);
  margin: 10px;
  max-width: 90%;
  height: auto;
}

.squircle-bg-large h3,
.squircle-bg-large h4,
.squircle-bg-large p,
.squircle-bg-large li {
  color: white;
  line-height: 1.25;
}

@media screen and (max-width: 680px) {
  .squircle-bg {
    margin: 10px 0;
    height: auto;
  }

  .flex-column-start {
    align-items: center;
  }
}

.hide-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

@media screen and (min-width: 2460px) {
  .nav-btn {
    height: 50px;
    font-size: 1.5rem;
    min-width: 200px;
    padding: 0 10px;
  }
}

@media screen and (max-width: 1023px) {
  .flex-column-start {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .top-heading, .top-heading-left {
    font-size: 40px;
    font-weight: 300;
  }

  .sub-heading, .sub-heading-small, .sub-heading-left, .sub-heading-small-left {
    font-size: 26px;
    font-weight: 300;
  }

  h1,
h2,
h3,
h4,
p {
    padding: 0 5px;
    color: #002149;
    text-align: center;
  }
}

@media screen and (max-width: 950px) {
  .top-heading, .top-heading-left {
    font-size: 40px;
    font-weight: 300;
  }

  .sub-heading, .sub-heading-small, .sub-heading-left, .sub-heading-small-left {
    font-size: 26px;
    font-weight: 300;
  }

  .block {
    padding: 30px 10px;
  }
}

@media screen and (max-width: 550px) {
  .top-heading, .top-heading-left {
    font-size: 34px;
    font-weight: 300;
  }

  .sub-heading, .sub-heading-small, .sub-heading-left, .sub-heading-small-left {
    font-size: 20px;
    font-weight: 300;
  }

  .block-max {
    padding: 60px 0;
  }
}

.nav-bar {
  background-color: #e4002b;
  height: 70px;
  position: sticky;
  top: 0px;
  z-index: 10;
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  grid-template-columns: repeat(3, 1fr);
  gap: 0;
  padding: 0 30px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);
  transition: background-color 0.2s ease;
}

.nav-bar img {
  width: 60px;
  margin: 0;
  padding: 5px;
  border-radius: 5px;
  margin-right: 10px;
}

.nav-bar .btn-group1 {
  justify-self: flex-start;
  align-self: center;
  flex-wrap: nowrap;
}

.nav-bar .btn-group1 .nav-btn {
  text-decoration: none;
  margin-right: 50px;
  transition: color 0.2s ease, border 0.2s ease, background-color 0.2s ease, opacity 0.2s ease;
}

.nav-bar .btn-group2 {
  flex-wrap: nowrap;
  justify-self: center;
  align-self: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.nav-bar .btn-group2 .nav-btn {
  text-decoration: none;
  transition: color 0.2s ease, border 0.2s ease, background-color 0.2s ease, opacity 0.2s ease;
  height: 50px;
}

.nav-bar .btn-group3 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  justify-self: flex-end;
  align-self: center;
}

.nav-bar .btn-group3 #menu-label {
  color: white;
  margin: 0px 10px 0 0;
  font-weight: bold;
  font-size: 1.5rem;
  transition: color 0.3s ease;
}

.nav-bar .btn-group3 .nav-btn {
  text-decoration: none;
  margin-right: 50px;
  transition: color 0.2s ease, border 0.2s ease, background-color 0.2s ease, opacity 0.2s ease;
}

.nav-bar.menu-open {
  background-color: white;
}

.nav-bar.menu-open #menu-label {
  color: #e4002b;
}

.menu-btn {
  cursor: pointer;
  transition: all 0.2s ease-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}

.menu-btn .menu-btn__line {
  width: 36px;
  height: 5px;
  margin-bottom: 5px;
  background-color: white;
  transition: all 0.2s ease-out;
}

.menu-btn.close {
  transform: rotate(360deg);
}

.menu-btn.close .menu-btn__line:nth-child(1) {
  transform: rotate(45deg) translate(5px, 9.5px);
  background-color: #e4002b;
}

.menu-btn.close .menu-btn__line:nth-child(2) {
  opacity: 0;
}

.menu-btn.close .menu-btn__line:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -9.5px);
  background-color: #e4002b;
}

.nav-bar__menu {
  overflow: hidden;
  position: fixed;
  z-index: 10;
  width: 100vw;
  height: calc(100vh - 70px);
  background-color: white;
  transform: translateX(4000px);
  transition: all 0.5s ease;
}

.nav-bar__menu .mobile-nav-btns {
  display: none;
  margin-top: 40px;
}

.nav-bar__menu nav {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
}

.nav-bar__menu nav p,
.nav-bar__menu nav .link {
  margin: 10px 20px 5px;
  padding: 0;
  text-align: left;
  font-size: 36px;
  text-decoration: none;
  text-transform: uppercase;
  line-height: 1.15;
  letter-spacing: 2px;
  color: #002149;
  transition: color 0.2s ease;
  cursor: pointer;
}

.nav-bar__menu nav p:hover,
.nav-bar__menu nav .link:hover {
  color: #e4002b;
}

.nav-bar__menu nav p.active,
.nav-bar__menu nav .link.active {
  color: #e4002b;
}

.nav-bar__menu nav .spacer-for-line {
  margin-bottom: 15px;
}

.nav-bar__menu nav .link-underline {
  color: transparent;
  width: 0px;
  margin-bottom: 10px;
  margin-left: 20px;
  height: 3px;
  background-color: #002149;
  transition: width 0.2s ease-in, color 0.2s ease;
}

.nav-bar__menu nav .link-underline.active {
  background-color: #e4002b;
}

.spotlight {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  margin-top: 20px;
  width: 85%;
  height: 150px;
  background: white;
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.74);
  border-radius: 20px;
  padding: 15px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.spotlight:hover {
  transform: scale(1.02);
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.5);
}

.spotlight:nth-of-type(2) img {
  order: 2;
}

.spotlight img {
  width: 175px;
  border-radius: 10px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.575);
}

.spotlight .text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;
  margin-left: 25px;
}

.spotlight .text-container h3 {
  text-align: center;
  text-decoration: none;
  margin-bottom: 0px;
  color: #002149;
  font-size: 26px;
}

.spotlight .text-container p {
  text-align: center;
  margin-top: 10px;
}

.nav-bar__menu.open {
  transform: translateX(0px);
}

.nav-header {
  font-size: 44px;
  text-align: center;
  font-weight: 200;
  color: white;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin: 40px 0;
  border-radius: 3px;
  transition: color 0.15s ease, background-color 0.15s ease;
}

.nav-header:hover {
  color: #e4002b;
  background-color: white;
}

.nav-section-header {
  color: white;
  font-size: 20px;
  text-transform: capitalize;
  margin: 0;
  padding-bottom: 15px;
}

.nav-link__menu {
  min-height: 550px;
  width: 675px;
  background-color: #002149;
  position: absolute;
  top: 8%;
  right: 4%;
  box-shadow: 0 20px 70px rgba(0, 0, 0, 0.562);
  border-radius: 20px;
  opacity: 0;
  transform: translateX(1000px);
  transition: opacity 0.15s ease-in-out, transform 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.nav-link__menu .nav-link__menu-backbtn {
  display: none;
  position: absolute;
  top: 2%;
  left: 2%;
  cursor: pointer;
  margin: 0px;
  transition: transform 0.2s ease;
}

.nav-link__menu .nav-link__menu-backbtn i {
  color: white;
}

.nav-link__menu .nav-link__menu-backbtn p {
  color: white;
  font-size: 16px;
  text-transform: capitalize;
  margin: 0 2px;
}

.nav-link__menu .nav-link__menu-backbtn:hover {
  transform: translateX(-2px);
}

.nav-link__menu h2 {
  font-size: 40px;
  text-align: center;
  font-weight: 200;
  color: white;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-decoration: dotted;
  margin: 15px 0;
}

.nav-link__menu .spacer {
  margin-top: 15px;
}

.nav-link__menu .nav-link {
  margin: 5px 5px;
  color: white;
  font-size: 16px;
  padding: 5px;
  text-transform: capitalize;
  text-align: center;
  border-radius: 3px;
  width: 90%;
  transition: color 0.15s ease, background-color 0.15s ease;
  text-decoration: underline;
  text-decoration-thickness: 1px;
}

.nav-link__menu .nav-link:hover {
  color: #e4002b;
  background-color: white;
}

.nav-link__menu a {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.square1, .countdown__square1 {
  height: 150px;
  width: 150px;
  background-color: #a6a6a6;
  position: absolute;
  top: 4%;
  right: 50%;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.459);
  border-radius: 20px;
  opacity: 0;
  transform: translateX(1000px);
  transition: opacity 0.15s ease-in-out, transform 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.square2, .countdown__square2 {
  height: 220px;
  width: 220px;
  background-color: #e4002b;
  position: absolute;
  top: 68%;
  right: 1%;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.562);
  border-radius: 20px;
  opacity: 0;
  transform: translateX(1000px);
  transition: opacity 0.15s ease-in-out, transform 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.square3 {
  height: 175px;
  width: 175px;
  background-color: #a6a6a6;
  position: absolute;
  top: 73%;
  right: 48%;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.562);
  border-radius: 20px;
  opacity: 0;
  transform: translateX(1000px);
  transition: opacity 0.15s ease-in-out, transform 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.square4 {
  height: 100px;
  width: 100px;
  background-color: #e4002b;
  position: absolute;
  top: 4%;
  right: 2%;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.397);
  border-radius: 20px;
  opacity: 0;
  transform: translateX(1000px);
  transition: opacity 0.15s ease-in-out, transform 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.nav-link__menu.move {
  transform: translateX(0px);
  opacity: 1;
}

.square1.move, .move.countdown__square1,
.square2.move,
.move.countdown__square2,
.square3.move,
.square4.move {
  transform: translateX(0px);
  opacity: 1;
}

.nav-bar__menu-img {
  display: none;
  margin-right: 100px;
}

@media screen and (min-width: 1750px) {
  .square1, .countdown__square1,
.square3 {
    right: 43%;
  }

  .square4 {
    height: 150px;
    width: 150px;
  }
}

@media screen and (min-width: 1535px) {
  .nav-bar__menu-img {
    display: inline;
  }

  .square1, .countdown__square1,
.square3 {
    right: 48%;
  }

  .square4 {
    height: 150px;
    width: 150px;
  }

  .nav-link__menu {
    width: 800px;
    height: 600px;
  }
}

@media screen and (max-width: 1535px) {
  .nav-bar__menu-img {
    display: inline;
    width: 450px;
  }
}

@media screen and (min-width: 1880px) {
  .nav-bar__menu .spacer {
    margin-top: 40px;
  }
  .nav-bar__menu .nav-link {
    font-size: 20px;
  }
  .nav-bar__menu nav .link,
.nav-bar__menu nav p {
    font-size: 48px;
  }
  .nav-bar__menu .nav-link__menu {
    top: 12%;
    right: 5%;
    width: 900px;
    height: 700px;
  }
  .nav-bar__menu .nav-link__menu h2 {
    font-size: 54px;
  }

  .square1, .countdown__square1,
.square3 {
    right: 40%;
    height: 300px;
    width: 300px;
  }

  .square2, .countdown__square2,
.square4 {
    height: 250px;
    width: 250px;
  }

  .spotlight {
    height: 200px;
  }
  .spotlight img {
    width: 200px;
  }
  .spotlight .text-container h3 {
    font-size: 34px;
  }
  .spotlight .text-container p {
    font-size: 20px;
  }
}

@media screen and (min-width: 2460px) {
  .nav-bar {
    padding: 10px 40px;
    height: 90px;
  }
  .nav-bar .btn-group3 #menu-label {
    font-size: 2rem;
  }

  .menu-btn .menu-btn__line {
    width: 50px;
    height: 5px;
  }

  .nav-bar__menu .spacer {
    margin-top: 40px;
  }
  .nav-bar__menu .nav-link {
    font-size: 20px;
  }
  .nav-bar__menu nav .link,
.nav-bar__menu nav p {
    font-size: 72px;
  }
  .nav-bar__menu .nav-link__menu {
    top: 15%;
    right: 5%;
    width: 1000px;
    height: 800px;
  }
  .nav-bar__menu .nav-link__menu h2 {
    font-size: 60px;
  }

  .square1, .countdown__square1,
.square3 {
    right: 40%;
    height: 300px;
    width: 300px;
  }

  .square2, .countdown__square2,
.square4 {
    height: 250px;
    width: 250px;
  }

  .spotlight {
    height: 200px;
  }
  .spotlight img {
    width: 220px;
  }
  .spotlight .text-container h3 {
    font-size: 34px;
  }
  .spotlight .text-container p {
    font-size: 20px;
  }
}

@media screen and (min-width: 3900px) {
  .nav-bar__menu .spacer {
    margin-top: 40px;
  }
  .nav-bar__menu .nav-link {
    font-size: 32px;
    margin: 10px 5px;
  }
  .nav-bar__menu nav .link,
.nav-bar__menu nav p {
    font-size: 72px;
  }
  .nav-bar__menu .nav-link__menu {
    top: 15%;
    right: 5%;
    width: 1200px;
    height: 1000px;
  }
  .nav-bar__menu .nav-link__menu h2 {
    font-size: 68px;
  }

  .square1, .countdown__square1,
.square3 {
    right: 32%;
    height: 300px;
    width: 300px;
  }

  .square2, .countdown__square2,
.square4 {
    height: 250px;
    width: 250px;
  }

  .spotlight {
    height: 215px;
  }
  .spotlight img {
    width: 248px;
  }
  .spotlight .text-container h3 {
    font-size: 38px;
  }
  .spotlight .text-container p {
    font-size: 24px;
  }
}

@media screen and (max-width: 1370px) {
  .nav-bar {
    display: grid;
    grid-template-rows: repeat(1, 1fr);
    grid-template-columns: repeat(2, 1fr);
    gap: 0px;
  }
  .nav-bar .btn-group2 {
    display: none;
  }

  .nav-bar__menu {
    overflow-y: visible;
  }
  .nav-bar__menu .mobile-nav-btns {
    display: flex;
  }
  .nav-bar__menu nav {
    padding-top: 30px;
    align-items: center;
    flex-grow: 1;
  }
  .nav-bar__menu nav .link,
.nav-bar__menu nav p {
    text-align: center;
    font-size: 30px;
    margin: 12px 5px;
  }
  .nav-bar__menu nav .spacer-for-line {
    margin-bottom: 5px;
  }
  .nav-bar__menu nav .link-underline {
    display: none;
  }
  .nav-bar__menu .square1, .nav-bar__menu .countdown__square1,
.nav-bar__menu .square2,
.nav-bar__menu .countdown__square2,
.nav-bar__menu .square3,
.nav-bar__menu .square4 {
    display: none;
  }

  .nav-link__menu {
    top: 0;
    right: 0;
    min-height: 100%;
    width: 100%;
    border-radius: 0;
    padding-bottom: 40px;
    transform: translateX(1500px);
  }
  .nav-link__menu .nav-link {
    font-size: 24px;
  }
  .nav-link__menu .nav-link__menu-backbtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 1073px) {
  .nav-bar__menu-img {
    display: none;
  }
}

@media screen and (max-width: 1023px) {
  .nav-bar {
    display: grid;
    grid-template-rows: repeat(1, 1fr);
    grid-template-columns: repeat(2, 1fr);
    gap: 0px;
  }
  .nav-bar .btn-group2 {
    display: none;
  }

  .nav-bar__menu nav .mobile-nav-btns {
    display: flex;
  }

  .nav-link__menu h2 {
    margin-top: 45px;
  }
  .nav-link__menu .nav-link {
    font-size: 24px;
  }
}

@media screen and (max-width: 768px) {
  .spotlight {
    width: 90%;
  }

  .nav-bar .btn-group1 .nav-btn {
    display: none;
  }
}

@media screen and (max-width: 550px) {
  .nav-bar {
    padding: 0px 10px;
  }
  .nav-bar .btn-group3 #menu-label {
    font-size: 1rem;
  }

  .nav-bar__menu nav {
    padding-bottom: 85px;
  }
  .nav-bar__menu a {
    font-size: 20px;
  }
  .nav-bar__menu .nav-link {
    font-size: 12px;
  }

  .spotlight {
    width: 95%;
    height: 150px;
  }
  .spotlight img {
    width: 140px;
  }
  .spotlight .text-container {
    align-items: center;
  }
  .spotlight .text-container h3 {
    font-size: 18px;
    text-align: center;
  }
  .spotlight .text-container p {
    font-size: 12px;
    line-height: 14px;
    text-align: center;
  }
}

@media screen and (max-width: 475px) {
  .spotlight .text-container {
    margin-left: 0;
  }
}

@media screen and (max-width: 350px) {
  .menu-btn .menu-btn__line {
    width: 30px;
    height: 3px;
  }

  .menu-btn.close .menu-btn__line:nth-child(1) {
    transform: rotate(45deg) translate(5px, 6.5px);
  }
  .menu-btn.close .menu-btn__line:nth-child(3) {
    transform: rotate(-45deg) translate(5px, -6.5px);
  }

  .nav-bar .nav-btn {
    width: auto;
  }
  .nav-bar .btn-group3 #menu-label {
    margin-right: 3px;
  }
}

.glide {
  position: relative;
  width: 100%;
  box-sizing: border-box;
}

.glide * {
  box-sizing: inherit;
}

.glide__track {
  overflow: hidden;
}

.glide__slides {
  position: relative;
  width: 100%;
  list-style: none;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  touch-action: pan-Y;
  overflow: hidden;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  will-change: transform;
}

.glide__slides--dragging {
  user-select: none;
}

.glide__slide {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  white-space: normal;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}

.glide__slide a {
  user-select: none;
  -webkit-user-drag: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.glide__arrows {
  -webkit-touch-callout: none;
  user-select: none;
}

.glide__bullets {
  -webkit-touch-callout: none;
  user-select: none;
}

.glide--rtl {
  direction: rtl;
}

.glide__arrow {
  position: absolute;
  display: block;
  top: 50%;
  z-index: 2;
  color: white;
  text-transform: uppercase;
  padding: 9px 12px;
  background-color: transparent;
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 4px;
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.1);
  text-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.1);
  opacity: 1;
  cursor: pointer;
  transition: opacity 150ms ease, border 300ms ease-in-out;
  transform: translateY(-50%);
  line-height: 1;
}

.glide__arrow:focus {
  outline: none;
}

.glide__arrow:hover {
  border-color: white;
}

.glide__arrow--left {
  left: 2em;
}

.glide__arrow--right {
  right: 2em;
}

.glide__arrow--disabled {
  opacity: 0.33;
}

.glide__bullets {
  position: absolute;
  z-index: 2;
  bottom: 2em;
  left: 50%;
  display: inline-flex;
  list-style: none;
  transform: translateX(-50%);
}

.glide__bullet {
  background-color: rgba(255, 255, 255, 0.5);
  width: 9px;
  height: 9px;
  padding: 0;
  border-radius: 50%;
  border: 2px solid transparent;
  transition: all 300ms ease-in-out;
  cursor: pointer;
  line-height: 0;
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.1);
  margin: 0 0.25em;
}

.glide__bullet:focus {
  outline: none;
}

.glide__bullet:hover, .glide__bullet:focus {
  border: 2px solid white;
  background-color: rgba(255, 255, 255, 0.5);
}

.glide__bullet--active {
  background-color: white;
}

.glide--swipeable {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.glide--dragging {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.header-text-container {
  position: relative;
  text-align: center;
  width: 70%;
  margin: 0 auto;
  min-height: 275px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.squircle-small-blue {
  width: 220px;
  height: 220px;
  position: absolute;
  right: -25%;
  top: 20%;
  background-color: #002149;
  border-radius: 15px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.507);
}

.squircle-small-red {
  width: 70px;
  height: 70px;
  position: absolute;
  top: 5%;
  right: -18%;
  background-color: #e4002b;
  border-radius: 30px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.507);
}

.squircle-large-blue {
  width: 250px;
  height: 250px;
  position: absolute;
  top: 2%;
  right: -18%;
  background-color: #e4002b;
  border-radius: 25px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.507);
}

.squircle-large-red {
  width: 200px;
  height: 200px;
  position: absolute;
  left: -23%;
  bottom: 10%;
  background-color: #e4002b;
  border-radius: 25px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.507);
}

@media screen and (max-width: 1370px) {
  .squircle-small-blue {
    width: 125px;
    height: 125px;
  }

  .squircle-large-red {
    width: 150px;
    height: 150px;
  }
}

@media screen and (max-width: 1023px) {
  .squircle-small-blue {
    width: 125px;
    height: 125px;
  }

  .squircle-large-red {
    left: -30%;
    width: 150px;
    height: 150px;
  }
}

@media screen and (max-width: 950px) {
  .squircle-small-blue {
    width: 125px;
    height: 125px;
  }

  .squircle-large-red {
    left: -30%;
    width: 150px;
    height: 150px;
  }
}

@media screen and (max-width: 768px) {
  .squircle-small-blue {
    right: -35%;
    width: 125px;
    height: 125px;
  }

  .squircle-large-red {
    width: 150px;
    height: 150px;
    left: -38%;
  }
}

@media screen and (max-width: 550px) {
  .header-text-container {
    width: 95%;
  }

  .text-container {
    width: 90%;
  }

  .squircle-small-blue {
    display: none;
  }

  .squircle-large-red {
    display: none;
  }
}

.info-page-header {
  margin: 0 auto;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 80px;
  background-image: linear-gradient(180deg, transparent 75%, white 100%), url("header.c4bdc8b5.jpg");
  background-position: center;
  background-repeat: repeat;
}

.info-page-header .header-text-container {
  min-height: 500px;
}

.info-page-header .header-text-container .top-heading, .info-page-header .header-text-container .top-heading-left {
  color: white;
  text-shadow: 1px 0px 12px black, -1px 0px 12px black, 0px 1px 12px black, 0px -1px 12px black;
}

.info-page-header .header-text-container .sub-heading, .info-page-header .header-text-container .sub-heading-small, .info-page-header .header-text-container .sub-heading-left, .info-page-header .header-text-container .sub-heading-small-left {
  color: white;
  text-shadow: 1px 0px 12px black, -1px 0px 12px black, 0px 1px 12px black, 0px -1px 12px black;
}

.info-page-header .header-text-container p {
  color: white;
  text-shadow: 3px 3px 5px black;
}

.info-page-header .header-text-container .inline-link:hover {
  color: white;
}

.info-page-header .header-text-container .squircle-small-blue {
  background-color: white;
}

@media screen and (max-width: 1100px) {
  .info-page-header {
    background-image: linear-gradient(180deg, transparent 75%, white 100%), url("header.c4bdc8b5.jpg");
  }
}

.ease-grant-header {
  background-image: url("Ease-grant-background.a6c0241e.webp");
  background-position: center;
}

.ease-grant-header .ease-grant-header__text {
  padding-left: 30px;
}

@media screen and (max-width: 1024px) {
  .ease-grant-header .ease-grant-header__text {
    padding-left: 0;
    padding: 10px 15px;
  }
}

.countdown {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  min-height: 700px;
}

.countdown-timer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.countdown-timer__block {
  border-radius: 15px;
  padding: 25px 30px;
  margin: 10px 20px;
  border: 2px solid #e4002b;
  width: 240px;
  height: 150px;
}

.countdown-timer__block #countdown-timer-days,
.countdown-timer__block #countdown-timer-hours,
.countdown-timer__block #countdown-timer-minutes,
.countdown-timer__block #countdown-timer-seconds {
  text-align: center;
  font-size: 55px;
  text-transform: uppercase;
  font-weight: bold;
  color: #002149;
  letter-spacing: 6px;
  margin: 0;
}

.countdown-timer__block-small {
  border-radius: 15px;
  padding: 20px 5px;
  margin: 10px 20px;
  border: 2px solid #e4002b;
  min-width: 140px;
  height: 122px;
  background-color: white;
}

.countdown-timer__block-small #countdown-timer-days,
.countdown-timer__block-small #countdown-timer-hours,
.countdown-timer__block-small #countdown-timer-minutes,
.countdown-timer__block-small #countdown-timer-seconds {
  text-align: center;
  font-size: 40px;
  text-transform: uppercase;
  font-weight: bold;
  color: #002149;
  letter-spacing: 6px;
  margin: 0;
  text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.404);
}

.countdown__square1 {
  transform: translateX(0);
  opacity: 1;
  top: 330px;
  right: 4%;
}

.countdown__square2 {
  transform: translateX(0);
  opacity: 1;
  top: 300px;
  left: 3%;
}

.home-countdown-block {
  max-width: 760px;
}

@media screen and (min-width: 1800px) {
  .countdown__square1 {
    right: 6%;
  }

  .countdown__square2 {
    left: 6%;
  }
}

@media screen and (min-width: 1450px) {
  .countdown__square1 {
    top: 275px;
    width: 250px;
    height: 250px;
    right: 4%;
  }

  .countdown__square2 {
    top: 250px;
    width: 300px;
    height: 300px;
    left: 3%;
  }
}

@media screen and (max-width: 1229px) {
  .countdown__square1,
.countdown__square2 {
    display: none;
  }
}

.car-show-header {
  background-image: linear-gradient(45deg, transparent 0%, #002149a1 0%), url("Hodges-car-show-U-shot.a22aaa0b.jpg");
  background-position-y: bottom;
  background-position-x: center;
}

.carShow-glide {
  border-radius: 20px;
  max-height: 322px;
  width: auto;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.425);
  cursor: grab;
}

.padding-20 {
  padding: 20px 0;
}

.height-334 {
  max-height: 334px;
  max-width: 258px;
}

@media screen and (max-width: 1200px) {
  .car-show-header {
    background-image: linear-gradient(45deg, transparent 0%, #002149a1 0%), url("Hodges-car-show-portrait-shot.6f1ea791.jpg");
  }
}

.transfer-portal {
  border-radius: 25px;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.425);
  display: flex;
  width: 100%;
}

.transfer-portal .content {
  flex-basis: 50%;
  padding: 10px 20px;
}

.transfer-portal img {
  width: 0;
  margin: 0;
  flex-basis: 50%;
  border-radius: 0 25px 25px 0;
}

@media screen and (max-width: 1150px) {
  .transfer-portal {
    flex-wrap: wrap;
  }
  .transfer-portal .content {
    flex-basis: auto;
  }
  .transfer-portal img {
    flex-basis: auto;
    width: 100%;
    border-radius: 0 0 25px 25px;
  }
}

@media screen and (max-width: 450px) {
  .transfer-portal .content {
    padding: 15px 5px;
  }
  .transfer-portal .content .btn__small-text {
    margin: 20px 0;
  }
}

a {
  text-decoration: none;
}

.block-link {
  position: relative;
  background-color: #e4002b;
  background-position: center;
  background-size: cover;
  margin: 20px 20px;
  padding: 5px 15px;
  width: 250px;
  height: 200px;
  border-radius: 25px;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.63);
  text-align: center;
  transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.block-link p {
  text-decoration: none;
  color: white;
  font-weight: bold;
  font-size: 18px;
  text-shadow: 3px 3px 5px black;
  letter-spacing: 2px;
}

.block-link img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.block-link:hover {
  box-shadow: 0 30px 60px rgba(0, 0, 0, 0.308);
  transform: scale(1.05);
}

.gdpr {
  background-color: white;
  width: 100%;
  max-height: 80%;
  overflow-y: auto;
  box-shadow: 0 0 30px 10px rgba(0, 0, 0, 0.486);
  position: fixed;
  bottom: 0;
  z-index: 100;
  opacity: 0;
  transform: translateY(1000px);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.gdpr h3 {
  margin: 0 15px 0 0;
}

.gdpr p {
  font-size: 10px;
  text-align: center;
  max-height: 250px;
}

.gdpr.open {
  transform: translateY(0px);
  opacity: 1;
}

.translator {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.445);
  border-radius: 15px;
  padding: 10px 30px;
}

.translator h3 {
  text-align: center;
  font-size: 40px;
  margin-bottom: 0;
  margin-top: 10px;
  font-weight: 300;
}

.translator h4 {
  color: #002149;
}

.translator a {
  margin: 0 10px 5px;
  color: #e4002b;
  font-weight: bold;
  transition: color 0.2s ease-in-out;
}

.translator a:hover {
  color: #002149;
}

.translator select {
  outline: 0;
  border: 2px solid #002149;
  border-radius: 20px;
  padding: 3px 7px;
  color: #002149;
  max-width: 90%;
  margin: 0 auto 15px;
}

.translator-fixed {
  position: fixed;
  z-index: 20;
  bottom: 70px;
  left: 5%;
  width: 250px;
  border-radius: 15px;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.384);
  transform: translateY(50px);
  transition: transform 1s ease-in-out;
}

.translator-fixed.closed {
  transform: translateY(205px);
}

.translator-fixed .translator-tab {
  background: #002149;
  cursor: pointer;
  letter-spacing: 2px;
  border-radius: 15px 15px 0 0;
  width: 100%;
}

.translator-fixed .translator-tab h4 {
  font-size: 1.5rem;
}

.translator-fixed .translator-fixed__translate {
  background: white;
  border-radius: 0 0 15px 15px;
}

.translator-fixed .translator-fixed__translate h3 {
  text-align: center;
}

.translator-fixed .translator-fixed__translate select {
  outline: 0;
  border: 2px solid #002149;
  border-radius: 20px;
  padding: 3px 7px;
  margin-bottom: 15px;
  color: #002149;
  width: 90%;
}

.translator-fixed .translator-fixed__translate a {
  margin: 0 10px 5px;
  color: #e4002b;
  font-weight: bold;
  transition: color 0.2s ease-in-out;
}

.translator-fixed .translator-fixed__translate a:hover {
  color: #002149;
}

.translate-link {
  text-decoration: none;
  color: #002149;
  font-weight: bold;
}

a.gflag {
  vertical-align: middle;
  font-size: 16px;
  padding: 1px 5px;
  background-repeat: no-repeat;
  background-image: url("//gtranslate.net/flags/16.png");
  text-decoration: none;
  color: #002149;
  font-weight: bold;
}

a.gflag img {
  border: 0;
  margin: 0;
}

a.gflag:hover {
  background-image: url("//gtranslate.net/flags/16a.png");
}

#goog-gt-tt {
  display: none !important;
}

.goog-te-banner-frame {
  display: none !important;
}

.goog-te-menu-value:hover {
  text-decoration: none !important;
}

body {
  top: 0 !important;
}

#google_translate_element2 {
  display: none !important;
}

footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding-bottom: 50px;
}

footer h2 {
  font-size: 48px;
  font-weight: 300;
  text-align: center;
  letter-spacing: 2px;
}

footer h3 {
  color: #002149;
  letter-spacing: 1.5px;
}

.featured-links {
  width: 100%;
}

.featured-links .links {
  padding: 0 30px;
  margin: 40px 0px 25px;
}

.featured-links .link {
  justify-self: center;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 130px;
  height: 130px;
  color: white;
  margin: 20px;
  border-radius: 25px;
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.507);
  background-color: #002149;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.featured-links .link:hover {
  transform: scale(1.1);
  box-shadow: 0 20px 60px rgba(0, 0, 0, 0.322);
}

.featured-links .link a {
  color: white;
  text-align: center;
  text-decoration: none;
  line-height: 22px;
  font-weight: 700;
  padding: 0 8px;
}

.featured-links .link:nth-of-type(even) {
  background-color: #e4002b;
}

.footer-bottom {
  padding-top: 30px;
}

.footer-text-container {
  text-align: center;
}

.social-links .social-link {
  margin: 5px 15px;
  padding: 10px;
  width: 65px;
  background-color: white;
  border: 2px solid #002149;
  transition: border 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.social-links .social-link i {
  color: #002149;
  font-size: 32px;
  transition: color 0.3s ease-in-out;
}

.social-links .social-link:hover {
  border: 2px solid #e4002b;
  background-color: #e4002b;
}

.social-links .social-link:hover i {
  color: white;
}

.translator {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.445);
  border-radius: 15px;
  padding: 10px 30px;
  width: 80%;
  max-width: 320px;
}

.translator h3 {
  text-align: center;
  font-size: 40px;
  margin-bottom: 0;
  margin-top: 10px;
  font-weight: 300;
}

.translator h4 {
  color: #002149;
}

.translator select {
  outline: 0;
  border: 2px solid #002149;
  border-radius: 20px;
  padding: 3px 5px;
  margin-bottom: 15px;
}

.amazon-link {
  cursor: pointer;
  background-color: #002149;
  border-radius: 25px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.486);
  padding: 10px;
  margin: 15px 0;
  transition: transform 0.2s ease-in-out;
  max-width: 300px;
}

.amazon-link:hover {
  transform: scale(1.05);
}

.copyright {
  background: linear-gradient(180deg, white 0%, #e4002b 5%);
}

.copyright p {
  padding: 30px 15px 75px 15px;
  color: white;
  font-size: 12px;
  text-align: center;
}

@media screen and (max-width: 1225px) {
  .featured-links .links .link {
    width: 120px;
    height: 120px;
  }

  .translator {
    margin: 60px 10px 80px;
  }
}

@media screen and (max-width: 1023px) {
  .featured-links .links {
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }

  .translator {
    margin: 60px 10px 80px;
  }
}

@media screen and (max-width: 550px) {
  .translator {
    padding: 10px 0px;
  }
}

@media screen and (max-width: 475px) {
  .featured-links .link {
    width: 120px;
    height: 120px;
  }
  .featured-links .link a {
    font-size: 14px;
  }

  .amazon-link {
    margin: 15px 0;
  }
}
/*# sourceMappingURL=index.ea068adc.css.map */
