.countdown {
  position: relative;
  @include flex(column, center, center);
  overflow: hidden;
  min-height: 700px;
}

.countdown-timer {
  @include flex(row, center, center);
  flex-wrap: wrap;
}

.countdown-timer__block {
  border-radius: 15px;
  padding: 25px 30px;
  margin: 10px 20px;
  border: 2px solid $secondary;
  width: 240px;
  height: 150px;

  #countdown-timer-days,
  #countdown-timer-hours,
  #countdown-timer-minutes,
  #countdown-timer-seconds {
    text-align: center;
    font-size: 55px;
    text-transform: uppercase;
    font-weight: bold;
    color: $primary;
    letter-spacing: 6px;
    margin: 0;
  }
}

.countdown-timer__block-small {
  border-radius: 15px;
  padding: 20px 5px;
  margin: 10px 20px;
  border: 2px solid $secondary;
  min-width: 140px;
  height: 122px;
  background-color: white;

  #countdown-timer-days,
  #countdown-timer-hours,
  #countdown-timer-minutes,
  #countdown-timer-seconds {
    text-align: center;
    font-size: 40px;
    text-transform: uppercase;
    font-weight: bold;
    color: $primary;
    letter-spacing: 6px;
    margin: 0;
    text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.404);
  }
}

.countdown__square1 {
  @extend .square1;
  transform: translateX(0);
  opacity: 1;
  top: 330px;
  right: 4%;
}

.countdown__square2 {
  @extend .square2;
  transform: translateX(0);
  opacity: 1;
  top: 300px;
  left: 3%;
}

.home-countdown-block {
  max-width: 760px;
}

@media screen and (min-width: 1800px) {
  .countdown__square1 {
    right: 6%;
  }

  .countdown__square2 {
    left: 6%;
  }
}

@media screen and (min-width: 1450px) {
  .countdown__square1 {
    top: 275px;
    width: 250px;
    height: 250px;
    right: 4%;
  }

  .countdown__square2 {
    top: 250px;
    width: 300px;
    height: 300px;
    left: 3%;
  }
}

@media screen and (max-width: 1229px) {
  .countdown__square1,
  .countdown__square2 {
    display: none;
  }
}
